import { useState } from 'react'

const useReplayBtn = () => {
    const [replayBtnData, setReplayBtnData] = useState({
        raceNo: '',
        roundNo: ''
    })

    return { replayBtnData, setReplayBtnData }
}

export default useReplayBtn
