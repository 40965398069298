import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../views/Home'
import PrevTournament from '../views/PrevTournament'

const AllRoutes = () => {
  return (
    <Routes>
      <Route path='/'>
        <Route
          index
          element={<Home />}
        />
      </Route>
      <Route path='/previous-tournament-details' element={<PrevTournament />} />
    </Routes>
  )
}

export default AllRoutes
