import React, { useEffect, useState } from 'react'
import websiteIcon from '../../../assets/images/website-icon.png'
import twitterIcon from '../../../assets/images/twitter-icon.png'
import discordIcon from '../../../assets/images/discord-icon.png'
import soundOn from '../../../assets/images/sound-on-icon.png'

import { viewSocialData } from '../../../query/socialLink/socialLink.query'
import { useQuery } from 'react-query'
import { useWaxWallet } from '../../../contexts/WaxWalletContext';

const GameCanvas = ({ togglePlay, isSoundEnabled, userData, gameStateData }) => {
    const [socialLinks, setSocialLinks] = useState({})

    const {
        walletAddress,
        walletType,
        isConnected
    } = useWaxWallet();

    const iframeUrl =
        `http://192.168.11.81:3928/editor/external/?sRoot=http://192.168.11.56:3005/&address=${walletAddress ? walletAddress : null}&page=1&limit=5000&collectionName=${gameStateData.sCollectionName}&schemaName=${gameStateData.sSchema}&testnet=${gameStateData?.sTestnetMainnet === 'testnet' ? true : false}`


    // *GET SOCIAL INFO
    const { isLoading: socialIsLoading, isFetching: socialIsFetching } = useQuery('viewSocialLink', viewSocialData, {
        select: (data) => data.data.data,
        onSuccess: (response) => {
            setSocialLinks(response);

        }
    })

    return (
        <>
            <div className='social-mediaBar'>
                <ul className='social-media-group'>
                    <li><a href={Object.keys(socialLinks).length > 0 ? (socialLinks?.sWebsiteUrl) : 'https://www.anonymousedao.com/'} target='_blank'><img src={websiteIcon} alt="" /></a></li>
                    <li><a href={Object.keys(socialLinks).length > 0 ? (socialLinks?.sTwitterUrl) : "https://x.com/anonymousedao"} target='_blank'><img src={twitterIcon} alt="" /></a></li>
                    <li><a href={Object.keys(socialLinks).length > 0 ? (socialLinks?.sDiscordUrl) : "https://discord.gg/KYkXUsHn"} target='_blank'><img src={discordIcon} alt="" /></a></li>
                </ul>
            </div>
            <div className='game-canvas-section'>
                <div className={`sound-section ${isSoundEnabled ? 'active' : ''}`} onClick={togglePlay}>
                    <img src={soundOn} alt="" />
                </div>
                <div className="game-canvas" >
                    <iframe id="phaser-iframe" src={`${process.env.REACT_APP_CANVAS_ENDPOINT}?sRoot=${process.env.REACT_APP_API_ENDPOINT}&address=${walletAddress ? walletAddress : walletAddress}&page=1&limit=5000&collectionName=${gameStateData?.sCollectionName}&schemaName=${gameStateData.sSchema}&testnet=${gameStateData?.sTestnetMainnet === 'testnet' ? true : false}`} title="Game Canvas"></iframe>
                </div>
            </div>
        </>
    )

}
// userData?.data ? userData.data.waxAddress : null
export default GameCanvas
