import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Container, Modal, Row, Table } from 'react-bootstrap'
import LazyLoad from 'react-lazyload'
import { useQuery } from 'react-query'
import { getPrevTournamentDetails } from '../../query/tournament/tournament.query'
import moment from 'moment'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import DataTable from '../../shared/components/DataTable'
import { useLocation } from 'react-router-dom'
import { appendParams, parseParams } from '../../shared/components/util'
import TriggerTooltip from 'shared/components/Tooltip'

const PrevTournament = () => {
    const bgImage = localStorage.getItem('background')

    const TournamentListColumn = [
        { name: 'No.', isSort: true },
        { name: 'Tournament Name', internalName: 'sTournamentName', type: 1, isSort: false },
        { name: 'Collection Name', internalName: 'sCollectionName', type: 1, isSort: false },
        { name: 'Schema', internalName: 'sSchema', type: 1, isSort: false },
        { name: 'Total NFTs', internalName: 'nMaxNFTs', type: 1, isSort: false },
        { name: 'Created At', internalName: 'dCreatedDate', type: 1, isSort: false },
        { name: 'Actions', isSort: false }
    ]

    const location = useLocation()
    const params = useRef(parseParams(location.search))

    function getRequestParams (e) {
        const data = e ? parseParams(e) : params.current
        return {
            pageNumber: +data?.pageNumber?.[0] || 1,
            nStart: (+data?.pageNumber?.[0] - 1) || 0,
            search: data?.search || '',
            nLimit: data?.nLimit || 10,
            eStatus: data.eStatus || '',
            sort: data.sort || '',
            orderBy: +data.orderBy === 1 ? 'ASC' : 'DESC',
            totalElements: +data?.totalElements || 0
        }
    }

    const [requestParams, setRequestParams] = useState(getRequestParams())

    const [columns, setColumns] = useState(TournamentListColumn)
    const [prevTournament, setPrevTournament] = useState({})
    const [show, setShow] = useState({ open: false, data: {} })

    //* PREVIOUS TOURNAMENT
    useQuery(['previousTournament', requestParams], () => getPrevTournamentDetails(requestParams), {
        select: (data) => data?.data?.data,
        onSettled: (res, err) => {
            if (err) {
                setPrevTournament(err?.response?.data?.data)
            } else {
                setPrevTournament(res)
            }
        }
    })

    const handleHeaderEvent = useCallback((name, value) => {
        switch (name) {
            case 'rows':
                setRequestParams({ ...requestParams, nLimit: Number(value), pageNumber: 1 })
                appendParams({ nLimit: Number(value), pageNumber: 1 })
                break
            case 'search':
                setRequestParams({ ...requestParams, search: value, pageNumber: 1 })
                appendParams({ pageNumber: 1 })
                break
            default:
                break
        }
    }, [requestParams, setRequestParams])

    const handlePageEvent = useCallback((page) => {
        setRequestParams({ ...requestParams, pageNumber: page, nStart: page - 1 })
        appendParams({ pageNumber: page, nStart: page - 1 })
    }, [requestParams, setRequestParams])


    const handleClose = () => {
        setShow({ open: false, data: {} })
    }
    const handleShow = (data) => {
        setShow({ open: true, data })
    }

    useEffect(() => {
        document.title = 'Previous Tournament | Last Meme Standing'
    }, [])
    return (
        <>
            <div className="main-background">
                <LazyLoadImage
                    alt=''
                    src={bgImage}
                    width="100vw"
                    height="100vh"
                    style={{ objectFit: 'cover' }}
                    effect="blur"
                />
            </div>
            <main className='main-sec'>
                <Container fluid>
                    <Row className='inner-content mt-3'>
                        <Col lg={10} md={12}>
                            <section className="tournament-details mt-3">
                                <h5>Previous Tournament Details</h5>
                                <div className='line mt-2'></div>

                                {/* <h6 className='mt-4'>Tournament Details:</h6> */}
                                {/* <div className='creation-details mt-3'>
                                    <Row>
                                        <Col lg={4} md={4} xs={6}>
                                            <div className='data-title'>Collection Name</div>
                                            <div className='data-value'>{prevTournament?.raceData?.sCollectionName || 'No Data Found'}</div>
                                        </Col>
                                        <Col lg={4} md={4} xs={6}>
                                            <div className='data-title'>Schema Name</div>
                                            <div className='data-value'>{prevTournament?.raceData?.sSchema || 'No Data Found'}</div>
                                        </Col>
                                        <Col lg={4} md={4} xs={6} className='mt-lg-0 mt-md-0 mt-3'>
                                            <div className='data-title'>Collection Limit</div>
                                            <div className='data-value'>{prevTournament?.raceData?.nMaxNFTs || '0'}</div>
                                        </Col>
                                        <Col lg={4} md={4} xs={6} className='mt-3'>
                                            <div className='data-title'>Tournament Name</div>
                                            <div className='data-value'>{prevTournament?.raceData?.sTournamentName || 'No Data Found'}</div>
                                        </Col>
                                        <Col lg={4} md={4} xs={6} className='mt-3'>
                                            <div className='data-title'>Status</div>
                                            <div className={`data-value ${prevTournament?.raceData?.sTournamentState === 'Finished' ? 'finished' : prevTournament?.raceData?.sTournamentState === 'Playing' ? 'playing' : 'scheduled'}`}>{prevTournament?.raceData?.sTournamentState || 'No Data Found'}</div>
                                        </Col>
                                        <Col lg={4} md={4} xs={6} className='mt-3'>
                                            <div className='data-title'>Created At</div>
                                            <div className='data-value'>{prevTournament?.raceData?.dCreatedDate ? moment(prevTournament?.raceData?.dCreatedDate)?.format('DD MMM YYYY, hh:mm:ss A') : 'No Data Found'}</div>
                                        </Col>
                                    </Row>
                                </div> */}
                                {/* <h6 className='mt-4'>Winner List</h6> */}
                                {/* <Table responsive borderless >
                                    <thead>
                                        <tr>
                                            <th>No.:</th>
                                            <th>Asset ID</th>
                                            <th>NFT Name</th>
                                            <th>Wax Address</th>
                                            <th>Prize</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {prevTournament?.raceData?.aRoundNFT?.map((item, i) => {
                                            return (
                                                <tr key={item?.assetId}>
                                                    <td>{i + 1}</td>
                                                    <td>{item?.assetId || '-'}</td>
                                                    <td>{item?.sNFTName || '-'}</td>
                                                    <td>{item?.sOwnerName || '-'}</td>
                                                    <td>{prevTournament?.raceData?.aPrizes[i]?.sAmount || '-'}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table> */}

                                <DataTable
                                    columns={columns}
                                    header={{
                                        left: {
                                            rows: true,
                                            component: true,
                                        },
                                        right: {
                                            search: true,
                                            filter: false,
                                        }
                                    }}
                                    headerEvent={(name, value) => handleHeaderEvent(name, value)}
                                    totalRecord={prevTournament?.pagination?.totalRecords || 0}
                                    pageChangeEvent={handlePageEvent}
                                    // isLoading={isLoading || isFetching}
                                    pagination={{ currentPage: requestParams.pageNumber, pageSize: requestParams.nLimit }}
                                // component={<TournamentListFilters defaultValue={requestParams} setRequestParams={setRequestParams} />}
                                >
                                    {prevTournament?.raceData?.map((item, i) => {
                                        return (
                                            <tr key={item?._id}>
                                                <td>{i + 1}</td>
                                                <td>{item?.sTournamentName}</td>
                                                <td>{item?.sCollectionName}</td>
                                                <td>{item?.sSchema}</td>
                                                <td>{item?.nMaxNFTs}</td>
                                                <td>{moment(item?.dCreatedDate)?.format('DD MMM YYYY, hh:mm:ss A')}</td>
                                                <td><button className="replay-btn" onClick={() => handleShow(item)}> View </button></td>
                                            </tr>
                                        )
                                    })}
                                </DataTable>

                                <Modal show={show?.open} onHide={handleClose} className='tournament-details-modal'>
                                    <Modal.Header className='modal-heade' closeButton>
                                        <Modal.Title className='text-light'>Winner Details</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className='modal-body'>
                                        <div>
                                            <Table responsive borderless>
                                                <thead>
                                                    <tr>
                                                        <th>No.</th>
                                                        <th>Asset ID</th>
                                                        <th>NFT Name</th>
                                                        <th>Wax Address</th>
                                                        <th>Prizes</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {show?.data?.aRoundNFT?.map((item, i) => {
                                                        return (
                                                            <tr key={item?.assetId}>
                                                                <td>{i + 1}</td>
                                                                <td>{item?.assetId}</td>
                                                                <td>{item?.sNFTName}</td>
                                                                <td>{item?.sOwnerName}</td>
                                                                <td>
                                                                    {/* {show?.data?.aPrizes?.[i]?.sAmount} */}
                                                                    <TriggerTooltip className='user text-capitalize' data={show?.data?.aPrizes?.[i]?.sAmount || '-'} display={show?.data?.aPrizes?.[i]?.sAmount || '-'} />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </section>
                        </Col>
                    </Row>
                </Container>
            </main>
        </>
    )
}

export default PrevTournament
