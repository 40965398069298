import axios from '../../axios'

export const getRoundNFTList = () => {
    return axios.get(process.env.REACT_APP_API_ENDPOINT + `api/v1/game/tournament/roundNFTs?t=${new Date().getTime()}`)
}

export const getPrevTournamentList = (params) => {
    return axios.post(process.env.REACT_APP_API_ENDPOINT + `api/v1/game/tournament/prevTournamentOnRacing?t=${new Date().getTime()}&pageNumber=${params?.pageNumber}&size=${params?.size}&sort=${params?.sort}&orderBy=${'ASC'}&search=${params?.search}`)
}

export const getPrevTournamentDetails = (id) => {
    return axios.post(process.env.REACT_APP_API_ENDPOINT + `api/v1/game/tournament/prevTournamentOnRacingDetail/${id}`)
}