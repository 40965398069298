import { Buffer } from "buffer";
import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { WaxWalletProvider } from "./contexts/WaxWalletContext";
import AllRoutes from "./routes";

window.Buffer = Buffer;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
    useEffect(() => {
      console.log = () => {};
      console.warn = () => {};
      console.error = () => {};
  }, [])
  return (
    <>
      <WaxWalletProvider
        nodeUrl={"https://wax.eosphere.io"}
        chainId={
          "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4"
        }
      >
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <AllRoutes />
          </BrowserRouter>
        </QueryClientProvider>
      </WaxWalletProvider>
    </>
  );
}

export default App;