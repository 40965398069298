import React, { useCallback, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { formatTime } from "../../../shared/constant";

import { useWaxWallet } from "../../../contexts/WaxWalletContext";
const InventoryList = ({ sendMessageToPhaser, userData, inventoryData, raceRoundTeam, gameStateData, inventeryLoading, socket }) => {
  // console.log('%cinventoryData: ', 'color: #295F98', inventoryData);
  const [remainingTimes, setRemainingTimes] = useState([]);

  const { aRoundNFT, aAllLostNFT } = gameStateData;
  const tableContainerRef = useRef(null);
  const { walletAddress } = useWaxWallet();

  useEffect(() => {
    const nStartTimes = (Object.keys(inventoryData)?.length > 0) && inventoryData?.reqInventory?.map((inventery) => inventery.timer);
    (nStartTimes && nStartTimes?.length > 0) && setRemainingTimes(nStartTimes)
  }, [inventoryData]);

  const updateRemainingTimes = useCallback(() => {
    setRemainingTimes((prevTimes) => prevTimes.map((time) => (time > 0 ? time - 1000 : 0)));
  }, []);

  useEffect(() => {
    if (inventoryData?.reqInventory?.length > 0) {
      if (!socket || socket.disconnected || !socket.connected) {
        return;
      }

      const timer = setInterval(() => {
        updateRemainingTimes();
      }, 1000);

      return () => clearInterval(timer); // Clear interval on unmount
    }
  }, [inventoryData, updateRemainingTimes, socket]);

  useEffect(() => {
    const playingRowIndex = inventoryData?.reqInventory?.findIndex(
      (round) => round?.sNFTState === 'Playing'
    );
    if (playingRowIndex !== -1 && tableContainerRef.current) {
      const rows = tableContainerRef.current.querySelectorAll('tbody tr');
      if (rows[playingRowIndex]) {
        const rowHeight = rows[playingRowIndex].offsetHeight;
        const containerHeight = tableContainerRef.current.clientHeight;

        // Scroll to make the "Playing" row in the middle of the container
        const scrollPosition = rows[playingRowIndex].offsetTop - (containerHeight / 2) + (rowHeight / 2);

        tableContainerRef.current.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [inventoryData]);

  const handleReplayBtn = useCallback((nRaceNumber, nRoundNumber) => sendMessageToPhaser(nRaceNumber, nRoundNumber), [sendMessageToPhaser])
  const handleSalesUrl = useCallback((url) => window.open(url, '_blank', 'noopener,noreferrer'), [])
  const liveNfts = inventoryData?.reqInventory?.filter((items) => items.sNFTState !== "Finished");
  return (
    <div className='inventory-list'>
      <div className="inventory-title">WALLET ADDRESS : {walletAddress?.length !== 0 ? walletAddress : " "} </div>
      <div className="active-race-details">
        <div className="activeRace-title">{Array.isArray(inventoryData?.reqInventory) ? inventoryData?.reqInventory?.length : '0'} ACTIVE RACERS</div>
        <div className="active-race-table" ref={tableContainerRef} style={{ maxHeight: '400px', overflowY: 'auto', position: "relative", scrollBehavior: "smooth", }}>
          <Table responsive borderless>
            <thead>
              <tr>
                <th>NAME</th>
                <th>POSITION</th>
                <th></th>
                <th>RACE START</th>
              </tr>
            </thead>
            <tbody>
              {!inventoryData.message ?
                (!inventeryLoading) ? liveNfts?.map((inventery, index) => {
                  const remainingTime = remainingTimes[index] || 0;
                  const isLive = raceRoundTeam?.data?.some((item) => item.sNFTName === inventery.sNFTName)
                  const position = aRoundNFT?.filter((item) => item.sNFTName === inventery.sNFTName)
                  return (
                    <tr key={index}>
                      <td>{inventery.sNFTName}</td>
                      <td>{position?.length > 0 ? (position?.map(item => item?.indexLeaderBoard + 1 || '-')) : '-'}</td>
                      <td> <button value={inventery.assetId} onClick={() => handleSalesUrl(inventery?.sURL)}>Sell</button></td>
                      <td>
                        {isLive ? ('LIVE') : (
                          <>
                            {inventery.sNFTState === 'NextRound' ? (
                              <button className="reply-btn" onClick={() => sendMessageToPhaser(inventery.nRaceNumber, inventery.nRoundNumber)}>
                                Replay
                              </button>
                            ) : inventery.sNFTState === 'Scheduled' ? (
                              <span>{formatTime(remainingTime)}</span>
                            ) : null}
                          </>
                        )}
                      </td>
                    </tr>
                  )
                }) : <tr> <td colSpan={4}>Loading...</td> </tr>
                : <tr> <td colSpan={4}>{inventoryData.message}</td> </tr>
              }
            </tbody>
          </Table>
        </div>
      </div>
      <div className="Eliminate-race-details">
        <div className="Eliminate-race-title">{Array.isArray(inventoryData?.eliminatedNFTs) ? inventoryData?.eliminatedNFTs?.length : '0'} Eliminated</div>
      </div>
      <div className="eliminate-race-table">
        <Table responsive borderless>
          <thead>
            <tr className="elim-header" >
              <th>NAME</th>
              <th>POSITION</th>
              <th>RACE START</th>
            </tr>
          </thead>
          <tbody>
            {!inventoryData.message ?
              (!inventeryLoading) ? inventoryData?.eliminatedNFTs?.map((inventery, index) => {
                const position = aAllLostNFT?.filter((item) => item.sNFTName === inventery.sNFTName)
                return position?.length > 0 ? <>
                  {position?.map(item => {
                    return (
                      <tr key={index}>
                        <td>{inventery?.sNFTName}</td>
                        <td>{item?.indexLeaderBoard + 1 || '-'}</td>
                        <td>
                          <button className="reply-btn" onClick={() => handleReplayBtn(inventery.nRaceNumber, inventery.nRoundNumber)}>
                            Replay
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </> : ''
              }) : <tr> <td colSpan={3}>Loading...</td> </tr>
              : <tr> <td colSpan={3}>NFT data not found</td> </tr>
            }
          </tbody>
        </Table>
      </div>
    </div >
  )
}

export default InventoryList;