import React from 'react'
import homeIcon from '../../../assets/images/logo.png'
import { useQuery } from 'react-query'
import { getSaleUrl } from '../../../query/banner/banner.query'
import LeaderboardList from '../LeaderBoard'
import { ReactToastify } from '../util'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const MiddleSideSection = ({ remainingNft, gameStateData, raceData, raceRoundTeam, onBannerClick, setOnBannerClick, bannerImg, handleBannerClick }) => {
  // *GET sale URl
  useQuery('saleUrl', getSaleUrl, {
    enabled: onBannerClick,
    select: (data) => data.data.data,
    onSuccess: (response) => {
      // window.location.href = response.sSaleUrl;
      window.open(response, '_blank', 'noopener,noreferrer');
      setOnBannerClick(false);
    },
    onError: (error) => {
      ReactToastify('Failed to Navigate Sale Url', 'error');
      setOnBannerClick(false);
    }
  })

  return (
    <>
      <div className="middle-side-section">
        <div className="last-meme-standing">
          <img src={homeIcon} alt="" />
        </div>
        <div className="price-pool-banner">
          <LazyLoadImage
            alt=''
            src={bannerImg}
            width='100%'
            height='200px'
            style={{ objectFit: 'cover' }}
            effect="blur"
            onClick={() => handleBannerClick()}
          />
        </div>

        <div className='tournament-status'>
          <div className='tournament-status-title'>tournament status</div>
          <div className='d-flex justify-content-between'>
            <div className='status'>
              <div>remaining</div>
              <div className='status-value'>{!isNaN(remainingNft) ? remainingNft : '0'}</div>
            </div>
            <div className='status'>
              <div>eliminated</div>
              <div className='status-value'>{gameStateData?.aTournamentLostNFT ?? "0"}</div>
            </div>
          </div>
        </div>

        <div className="leaderboard">
          <LeaderboardList raceData={raceData} />
        </div>
      </div>
    </>
  )
}

export default MiddleSideSection
