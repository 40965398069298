import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Container, Modal, Row, Table } from 'react-bootstrap'
import { useMutation, useQuery } from 'react-query'
import { getPrevTournamentDetails, getPrevTournamentList } from '../../query/tournament/tournament.query'
import moment from 'moment'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import DataTable from '../../shared/components/DataTable'
import { useLocation } from 'react-router-dom'
import { appendParams, parseParams } from '../../shared/components/util'
import TriggerTooltip from 'shared/components/Tooltip'

const PrevTournament = () => {
    const bgImage = localStorage.getItem('background')

    const TournamentListColumn = [
        { name: 'No.', isSort: true },
        { name: 'Tournament Name', internalName: 'sTournamentName', type: 1, isSort: false },
        { name: 'Collection Name', internalName: 'sCollectionName', type: 1, isSort: false },
        { name: 'Schema', internalName: 'sSchema', type: 1, isSort: false },
        { name: 'Total NFTs', internalName: 'nMaxNFTs', type: 1, isSort: false },
        { name: 'Started At', internalName: 'dCreatedDate', type: 1, isSort: false },
        { name: 'Actions', isSort: false }
    ]

    const location = useLocation()
    const params = useRef(parseParams(location.search))

    function getRequestParams (e) {
        const data = e ? parseParams(e) : params.current
        return {
            pageNumber: +data?.pageNumber?.[0] || 1,
            // size: (+data?.pageNumber?.[0] - 1) || 0,
            search: data?.search || '',
            size: data?.size || 10,
            sort: data.sort || new Date(),
            orderBy: +data.orderBy === 1 ? 'ASC' : 'DESC',
        }
    }

    const [requestParams, setRequestParams] = useState(getRequestParams())

    const [columns, setColumns] = useState(TournamentListColumn)
    const [prevTournament, setPrevTournament] = useState({})
    const [nftDetails, setNftDetails] = useState([])
    const [show, setShow] = useState({ open: false, data: {} })

    //* PREVIOUS TOURNAMENT
    useQuery(['previousTournament', requestParams], () => getPrevTournamentList(requestParams), {
        select: (data) => data?.data?.data,
        onSettled: (res, err) => {
            if (err) {
                setPrevTournament(err?.response?.data?.data)
            } else {
                setPrevTournament(res)
            }
        }
    })

    //* PREVIOUS TOURNAMENT NFT DETAILS
    const { mutate } = useMutation(getPrevTournamentDetails, {
        onSettled: (res, err) => {
            if (err) {
                setNftDetails(err?.response?.data?.data)
            } else {
                setNftDetails(res?.data?.data)
                setShow({ open: true })
            }
        }
    })

    const handleHeaderEvent = useCallback((name, value) => {
        switch (name) {
            case 'rows':
                setRequestParams({ ...requestParams, size: Number(value), pageNumber: 1 })
                appendParams({ size: Number(value), pageNumber: 1 })
                break
            case 'search':
                setRequestParams({ ...requestParams, search: value, pageNumber: 1 })
                appendParams({ pageNumber: 1 })
                break
            default:
                break
        }
    }, [requestParams, setRequestParams])

    const handlePageEvent = useCallback((page) => {
        setRequestParams({ ...requestParams, pageNumber: page })
        appendParams({ pageNumber: page })
    }, [requestParams, setRequestParams])


    const handleClose = () => {
        setShow({ open: false, data: {} })
    }
    const handleShow = (id) => {
        console.log('id: ', id);
        // setShow({ open: true, id })
        mutate(id)
    }

    useEffect(() => {
        document.title = 'Previous Tournament | Last Meme Standing'
    }, [])
    return (
        <>
            <div className="main-background">
                <LazyLoadImage
                    alt=''
                    src={bgImage}
                    width="100vw"
                    height="100vh"
                    style={{ objectFit: 'cover' }}
                    effect="blur"
                />
            </div>
            <main className='main-sec'>
                <Container fluid>
                    <Row className='inner-content mt-3'>
                        <Col lg={10} md={12}>
                            <section className="tournament-details mt-3">
                                <h5>Previous Tournament Details</h5>
                                <div className='line mt-2'></div>
                                <DataTable
                                    columns={columns}
                                    header={{
                                        left: {
                                            rows: true,
                                            component: true,
                                        },
                                        right: {
                                            search: true,
                                            filter: false,
                                        }
                                    }}
                                    headerEvent={(name, value) => handleHeaderEvent(name, value)}
                                    totalRecord={prevTournament?.pagination?.totalRecords || 0}
                                    pageChangeEvent={handlePageEvent}
                                    // isLoading={isLoading || isFetching}
                                    pagination={{ currentPage: requestParams.pageNumber, pageSize: requestParams.size }}
                                >
                                    {prevTournament?.raceData?.map((item, i) => {
                                        return (
                                            <tr key={item?._id}>
                                                <td>{i + 1}</td>
                                                <td>{item?.sTournamentName}</td>
                                                <td>{item?.sCollectionName}</td>
                                                <td>{item?.sSchema}</td>
                                                <td>{item?.nMaxNFTs}</td>
                                                <td>{moment(item?.dStartDate)?.format('DD MMM YYYY, hh:mm:ss A')}</td>
                                                <td><button className="replay-btn" onClick={() => handleShow(item?._id)}> View </button></td>
                                            </tr>
                                        )
                                    })}
                                </DataTable>

                                <Modal show={show?.open} onHide={handleClose} className='tournament-details-modal'>
                                    <Modal.Header className='modal-heade' closeButton>
                                        <Modal.Title className='text-light'>Winner Details</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className='modal-body'>
                                        <div>
                                            <Table responsive borderless>
                                                <thead>
                                                    <tr>
                                                        <th>No.</th>
                                                        <th>Asset ID</th>
                                                        <th>NFT Name</th>
                                                        <th>Wax Address</th>
                                                        <th>Prizes</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {nftDetails?.aTournamentNFT
                                                        ?.map((item, i) => {
                                                            return (
                                                                <tr key={item?.assetId}>
                                                                    <td>{i + 1}</td>
                                                                    <td>{item?.assetId}</td>
                                                                    <td>{item?.sNFTName}</td>
                                                                    <td>{item?.sOwnerName}</td>
                                                                    <td>
                                                                        {/* {show?.data?.aPrizes?.[i]?.sAmount} */}
                                                                        <TriggerTooltip className='user text-capitalize' data={nftDetails?.aPrizes?.[i]?.sAmount || '-'} display={show?.data?.aPrizes?.[i]?.sAmount || '-'} />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </section>
                        </Col>
                    </Row>
                </Container>
            </main>
        </>
    )
}

export default PrevTournament
