import React, { useState, useEffect, Fragment, useCallback, useRef } from 'react';
import { Modal, ModalBody, } from 'reactstrap';
import { Col, Container, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap'
import homeIcon from '../../assets/images/logo.png'
import iconClose from '../../assets/images/close.png'
import LeaderboardList from '../../shared/components/LeaderBoard';
import ScheduleRaceList from '../../shared/components/RaceScheduled';
import InventoryList from '../../shared/components/InventoryData';
import io from 'socket.io-client';
import { RfoxIdProvider, useIsLoggedIn, useRfoxId } from 'rfox-id-react'
import { RfoxId } from 'rfox-id'
import { Link, useLocation } from 'react-router-dom';
import GameCanvas from '../../shared/components/GameCanvas';
import { useQuery, useQueryClient } from 'react-query';
import { viewBackgroundImage, viewBannerData } from '../../query/banner/banner.query';
import { getBuyRaces } from '../../query/buyRaces/buyRaces.query';
import LoggedInView from '../../shared/components/LoggedInView';
import NotLoggedInView from '../../shared/components/NotLoggedInView';
import MiddleSideSection from '../../shared/components/MiddleSideSection';
import useReplayBtn from '../../shared/hooks/useReplayBtn';
import { playRandomSound, ReactToastify, setVolume, stopSound } from '../../shared/components/util';
import useMediaQuery from '../../shared/hooks/useMediaQuery';
// import bannerImage from '../../assets/images/banner-image.png'
import { removeCookie, setCookie } from '../../helper';
import { useWaxWallet } from "../../contexts/WaxWalletContext";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const sRootUrl = process.env.REACT_APP_API_ENDPOINT

const socket = io(sRootUrl + `?t=${new Date().getTime()}`, {
  transports: ['websocket'],
  timestampRequests: true,
  reconnectionDelay: 0,
  retries: 5
});

const Home = () => {
  const query = useQueryClient()
  const location = useLocation();
  const isLoggedIn = useIsLoggedIn();

  const rfoxId = useRfoxId();

  const width = useMediaQuery('(max-width: 576px)')

  const [raceData, setRaceData] = useState([]);
  const [rfoxidReady, setRfoxidReady] = useState(false);
  const [selectedTab, setSelectedTab] = useState({ schedule: true, inventory: false });
  const [inventoryData, setInventoryData] = useState({ message: 'NFT not found in tournament' });
  const [roundNFTs, setRoundNFTS] = useState([])
  const [userData, setUserData] = useState({});
  const [logginData, setLogginData] = useState(false);
  const [remainingNft, setRemainingNft] = useState(0);
  const [modal, setModal] = useState(false);
  const [raceTimer, setRaceTimer] = useState([]);
  const [isBuyRaces, setIsBuyRaces] = useState(false);
  const [raceRoundTeam, setRaceRoundTeam] = useState([]);
  const [msg, setMsg] = useState('');
  const [raceScheduleLoading, setRaceScheduleLoading] = useState(false)
  const [inventeryLoading, setInventeryLoading] = useState(false)
  const [isSoundEnabled, setIsSoundEnabled] = useState(false);
  const { replayBtnData, setReplayBtnData } = useReplayBtn()
  const [onBannerClick, setOnBannerClick] = useState(false);
  const [bannerImg, setBannerImg] = useState('')
  const [gameStateData, setGameStateData] = useState({});
  const [isbtnClick, setisbtnClick] = useState(false);
  const [backgroundImg, setBackgroundImg] = useState('')
  const [isStrargicTimoutZero, setIsStrargicTimoutZero] = useState(false)

  const {
    walletAddress,
    walletType,
    isConnected
  } = useWaxWallet();

  const rfoxID = new RfoxId({
    // rfoxIdUrl: "https://id-staging.rfoxvalt.com", //! DEV
    rfoxIdUrl: gameStateData?.sTestnetMainnet === 'testnet' ? "https://id-staging.rfoxvalt.com" : "https://id-dev.rfoxvalt.com", //! PROD
    // authCallbackUrl: "http://localhost:3000/", //! LOCAL
    authCallbackUrl: "https://memeracer-ui.rfox.com", //! PROD
    // authCallbackUrl: "http://meme-racer-react-panel.s3-website.ap-south-1.amazonaws.com/", //! STAG
  });

  // useEffect(() => {
  //   const checkMemoryUsage = () => {
  //     if (performance.memory) {
  //       const memoryUsage = performance.memory.usedJSHeapSize / performance.memory.jsHeapSizeLimit;
  //       console.log('%cMemory usage:', 'color: orange', memoryUsage);

  //       const memoryUsedMB = performance.memory.usedJSHeapSize / (1024 * 1024);
  //       const memoryLimitMB = performance.memory.jsHeapSizeLimit / (1024 * 1024);
  //       console.log(`%cMemory Used: ${memoryUsedMB.toFixed(2)} MB of ${memoryLimitMB.toFixed(2)} MB`, 'color: pink');

  //       //^ IF MEMORY USAGE EXCEED 900 MB IT WILL HARD RELOAD
  //       console.log('%cIs Memory Usage High ?', `color: ${performance.memory.usedJSHeapSize > 600 * 1024 * 1024 ? 'red' : 'white'}`, performance.memory.usedJSHeapSize > 900 * 1024 * 1024);
  //       // if (performance.memory.usedJSHeapSize > 900 * 1024 * 1024) {
  //       //   console.log('%cMemory usage high. Clearing cache...', 'color: red');
  //       //   const timestamp = new Date().getTime();
  //       //   window.location.href = `${window.location.origin}${window.location.pathname}?cacheBust=${timestamp}`;
  //       // }
  //       const jsMemoryThresholdMB = 270;

  //       console.log('%cIs Memory Usage High ?', `color: ${memoryUsedMB > jsMemoryThresholdMB ? 'red' : 'white'}`, memoryUsedMB > jsMemoryThresholdMB);
  //       if (memoryUsedMB > jsMemoryThresholdMB) {
  //         console.log('%cMemory usage high. Clearing cache...', 'color: red');

  //         // Reload with a cache-busting query parameter to refresh resources
  //         const timestamp = new Date().getTime();
  //         window.location.href = `${window.location.origin}${window.location.pathname}?cacheBust=${timestamp}`;
  //       }
  //     }
  //   };

  //   const interval = setInterval(checkMemoryUsage, 10000); // Check every 10 seconds

  //   return () => {
  //     clearInterval(interval); // Clean up interval on unmount
  //   };
  // }, []);

  //* AUDIO CONTROL BUTTON
  const toggleSound = () => {
    setIsSoundEnabled(prevIsSoundEnabled => {
      return !prevIsSoundEnabled;
    });
  }

  useEffect(() => {
    rfoxID.init();
    setRfoxidReady(true);
  });

  // *GET BUY MORE RACERS
  const { isLoading, isFetching, refetch } = useQuery('buyRaces', getBuyRaces, {
    enabled: isBuyRaces,
    select: (data) => data.data.data,
    onSuccess: (response) => {
      // window.location.href = response.sMarketPlacePrefixUrl
      // navigate(response.sMarketPlacePrefixUrl)
      window.open(response, '_blank', 'noopener,noreferrer')
      setIsBuyRaces(false)
    },
    onError: (error) => {
      ReactToastify(error?.response?.data?.message, 'error');
      setIsBuyRaces(false)
    }
  })

  // *GET BANNER IMAGE
  const { isLoading: bannerIsLoading } = useQuery('viewBanner', viewBannerData, {
    select: (data) => data.data.data,
    onSuccess: (response) => {
      setBannerImg(response)
    }
  })

  //* LOAD DEFAULT BANNER IMAGE
  // const bannerSrc = bannerIsLoading || !bannerImg
  //   ? defaultBackground
  //   : bannerImg;

  // *GET BACKGROUND IMAGE
  const { isLoading: bgIsLoading } = useQuery('viewBackground', viewBackgroundImage, {
    select: (data) => data.data.data?.[0],
    onSuccess: (response) => {
      setBackgroundImg(response?.sName)
      localStorage.setItem('background', response?.sName)
    }
  })

  //* LOAD DEFAULT BACKGROUND IMAGE
  // const backgroundSrc = bgIsLoading || !backgroundImg
  //   ? defaultBackground
  //   : backgroundImg;

  const toggle = useCallback(() => setModal(!modal), [modal])
  const handleClose = useCallback(() => {
    setModal(false)
    setMsg('')
  }, [setModal, setMsg])

  useEffect(() => {
    let timeoutId;
    const getEventData = (type, data, error) => {
      if (timeoutId) {
        // Clear the timeout if another event comes in within 2 seconds
        clearTimeout(timeoutId);
      }

      console.log('Event: ', data?.sEventName);
      switch (type) {
        case 'reqBeforeInitializeGame':
          if (error) {
            console.error('Error in reqBeforeInitializeGame:', error);
            return;
          }
          console.log('reqBeforeInitializeGame', data.oData)
          data?.reqBeforeInitializeGame && setRaceData((prevData) => {
            const shuffledData = data?.reqBeforeInitializeGame?.aRoundNFT;
            return shuffledData.map((item) => ({
              ...item,
              prevIndex: prevData?.findIndex((prevItem) => prevItem?.assetId === item?.assetId),
            }));
          });
          break;

        case 'raceTimer':
          if (error) {
            console.error('Error in raceTimer:', error);
            return;
          }
          console.log('%craceTimer', 'color: skyblue', data.oData)
          setRaceTimer(data?.oData?.oData?.data?.aCurrentRoundsNFT)
          break;

        case 'raceRoundTeam':
          if (error) {
            console.error('Error in raceRoundTeam:', error);
            return;
          }
          // emitJoinBoard()
          console.log('%craceRoundTeam', 'color: yellow', data.oData)
          // data?.oData?.dDateTime "2024-11-12T10:44:53.253Z"
          setRaceRoundTeam(data?.oData);
          setisbtnClick(true)
          break;

        case 'playerSequence':
          if (error) {
            console.error('Error in playerSequence:', error);
            return;
          }
          setRaceData(data?.oData?.data);
          break;

        case 'gameStates':
          console.log('%cgameStates', 'color: pink', data.oData)
          setRemainingNft(data?.oData?.gameState?.nMaxNFTs - data?.oData?.gameState?.aTournamentLostNFT)
          setGameStateData(data?.oData?.gameState)
          setRoundNFTS(data?.oData?.aRounds);

          if (data?.oData?.line === "initializeGame before gamePlay") {
            setRaceTimer(data?.oData?.aRounds);
            setIsStrargicTimoutZero(false)

            // timeoutId = setTimeout(() => {
            //   console.log("No event received within 2 seconds, emitting reqJoinBoard...");

            //   socket.emit('reqAckRaceRoundTeam', {});
            //   emitJoinBoard()

            // }, 2500); // 2-second delay

          }
          // if (res.line === 'beforeInitializeGame after gamePaly') {
          // }
          break;

        case 'raceStrategicTimeout':
          console.log('raceStrategicTimeout DP', data?.oData)
          let timeout = data.oData.raceStrategicTimeout;
          const interval = setInterval(() => {
            timeout -= 1000; // Decrement the timer by 1000ms (1 second)
            console.log(`Remaining Time: ${timeout}ms`);

            if (timeout <= 0) {
              clearInterval(interval); // Clear the interval when it reaches 0
              console.log('Timer reached 0');

              // setRaceScheduleLoading(true)
              // setRaceTimer([])
              setIsStrargicTimoutZero(true)
              // setRoundNFTS([])
              // getRaceScheduleData()
              // emitJoinBoard()
              // window.location.reload()
            }
          }, 1000)
          break;

        case 'roundStrategicTimeout':
          console.log('roundStrategicTimeout DP', data?.oData)
          break;
        case 'raceRoundResult':
          if (error) {
            console.error('Error in raceRoundResult:', error);
            return;
          }
          console.log('raceRoundResult', data?.oData)
          setRaceData([data?.oData.data]);
          // stopSound();
          query.invalidateQueries('viewSocialLink')
          query.invalidateQueries('viewBanner')
          // setisbtnClick(true)
          break;
        default:
          console.warn('Unhandled event type:', type);
      }
    };

    socket.on('resTournamentStartTimer', (data, error) => {
      console.log('%cresTournamentStartTimer: ', 'color: #6887ff', data, error);
      setRaceRoundTeam(data?.oData?.data);
      setisbtnClick(true)
      window.location.reload()
    })
    // ^ Listen:  connect_error
    socket?.on("connect_error", (error) => {
      console.log("%cConnection Error:", 'color: orange', error);
    })
    // ^ Listen: disconnect
    socket.on("disconnect", (reason, details) => {
      console.log('socket disconnect');
      if (reason === 'io server disconnect' || reason === 'io client disconnect') {
        socket.connect()
      }
    })

    const emitJoinBoard = () => {
      console.log('%ccalled', 'color: violet')
      // ~  Emit: Request to join board for refresh purpose
      socket.emit("reqJoinBoard", {}, (error, data) => {
        console.log('reqJoinBoard: ', data, error);
        if (data === undefined) {
          setRemainingNft(0);
          setRoundNFTS([]);
          setRaceRoundTeam([]);
          setRaceTimer([])
          setGameStateData({})
          setRaceData([]);
        } else {
          // setIsStrargicTimoutZero(false)

          console.log('here...')
          // console.log('data.oData.executionTime: ', data.oData.executionTime)
          // console.log('startDate: ', new Date(data.oData.dEndDate))
          // console.log('endDate: ', new Date(new Date().toISOString()))
          // console.log('diff', new Date(new Date().toISOString()) - new Date(data.oData.dEndDate))
          if (data !== null) {
            if (Object.keys(data?.oData).length !== 0 && data?.oData?.sTournamentState !== "No game found") {
              console.log('join board inside')
              setRemainingNft(data?.oData?.data?.nMaxNFTs - data?.oData?.data?.aTournamentLostNFT);
              setRoundNFTS(data?.oData?.aRounds);
              setRaceRoundTeam(data?.oData?.data?.aRaceRoundTeam);

              const latestTimer = data?.oData?.aRounds?.map(round => ({
                ...round,
                nRaceTimer: round.nRaceTimer - data.oData.executionTime
              }))
              // const latestTimer = data?.oData?.aRounds?.map((round, index) => ({
              //   ...round,
              //   nRaceTimer: index === 0
              //     ? round.nRaceTimer - data.oData.executionTime
              //     : round.nRaceTimer
              // }))
              setRaceTimer(latestTimer)
              setGameStateData(data?.oData?.data)
              setRaceData(data?.oData?.replayData);
              // setCollectionName(data?.oData?.data?.sCollectionName)
              // setSchemaName(data?.oData?.data?.sSchema)
              data?.oData && query.invalidateQueries('viewBackground')


              if (data.oData.data._id) {
                localStorage.setItem('Channel ID', data?.oData?.data?._id)
                socket.on(`race:${data.oData.data._id}`, (data) => {
                  getEventData(data?.sEventName, data, null);
                });
              } else {
                console.log('Channel ID not found');
              }
            }
          } else {
            console.log('No Tournament Found')
          }
        }
      })
    }

    emitJoinBoard()

    // Clean up timeout when component unmounts
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [socket]);

  // * on Click of raceScheduled Tab
  const getRaceScheduleData = useCallback(() => {
    socket.emit('reqRaceSchedule', {}, (error, data) => {
      console.log('%c RaceScheduledData', 'color: #ffa640', data);
      setRaceTimer(data?.oData?.aRounds)
      setRaceScheduleLoading(false)
    })
  }, [])

  // * Inventory Emit Function
  const getInventoryData = useCallback(() => {
    const oData = { address: walletAddress ? walletAddress : " ", page: 1, limit: 100, collectionName: gameStateData.sCollectionName, schemaName: gameStateData.sSchema, testnet: gameStateData?.sTestnetMainnet === 'testnet' ? true : false }

    // const oData = {
    //   address: 'acbq4.c.wam', 
    //   page: 1,
    //   limit: 30,
    //   collectionName: 'rockettomoon', 
    //   schemaName: 'rocket', 
    //   testnet: false 
    // };

    socket.emit("reqInventory", { ...oData }, (error, data) => {
      console.log('%creqInventory: ', 'color:  #ffa640', data);
      if (data.reqInventory?.message === 'No matched NFT found in tournament') {
        setInventoryData({ message: 'NFT not found in tournament' })
        setInventeryLoading(false)
      }
      if (Array.isArray(data?.reqInventory)) {
        setInventoryData(data)
        setInventeryLoading(false)
      }
    })
  }, [gameStateData.sCollectionName, gameStateData.sSchema, gameStateData?.sTestnetMainnet, walletAddress])

  useEffect(() => {
    if (isbtnClick) {
      getInventoryData()
      setisbtnClick(false)
    }
  }, [isbtnClick])

  const gameStateListenerRef = useRef(null);
  //* SOUND PLAY/ PAUSE
  useEffect(() => {
    if (!isSoundEnabled) {
      setVolume(0);
    }
    else {
      setVolume(1);
    }

    const getEventData = (type, data, error) => {
      switch (type) {
        case 'gameStates':
          console.log('Type: ', data.oData.line)
          if (data.oData.line === "beforeInitializeGame after gamePaly") {
            stopSound();
          }
          if (data.oData.line === "initializeGame before gamePlay") {
            // if (isSoundEnabled) {
            //   setTimeout(() => {
            //     playRandomSound();
            //   }, 1000)
            // } else {
            //   setTimeout(() => {
            //     stopSound();
            //   }, 1000)
            // }
          }
          break;
        case 'raceRoundTeam':
          if (isSoundEnabled) {
            setTimeout(() => {
              playRandomSound();
            }, 1500)
          } else {
            setTimeout(() => {
              stopSound();
            }, 1500)
          }
          break;
        default:
          console.warn('Unhandled event type:', type);
      }
    }

    socket.emit("reqJoinBoard", {}, (error, data) => {
      if (data !== undefined) {
        if (Object.keys(data?.oData).length !== 0 && data?.oData?.sTournamentState !== "No game found") {
          if (data.oData.data._id) {
            gameStateListenerRef.current = (eventData) => {
              if (eventData?.sEventName === 'gameStates') {
                getEventData('gameStates', eventData);
              } else if (eventData?.sEventName === 'raceRoundTeam') {
                getEventData('raceRoundTeam', eventData);
              }
            };

            // Attach the listener for `gameStates` specifically
            socket.on(`race:${data.oData.data._id}`, gameStateListenerRef.current);
          } else {
            console.log('Channel ID not found');
          }
        }
      } else {
        console.log('No Tournament Found')
      }
    })
    // const handleGameStates = (res) => {
    //   if (res.line === "beforeInitializeGame after gamePaly") {
    //     stopSound();
    //   }
    //   if (res.line === "initializeGame before gamePlay") {
    //     if (isSoundEnabled) {
    //       playRandomSound();
    //     } else {
    //       stopSound();
    //     }
    //   }
    // };
    // socket.on('gameStates', handleGameStates);
    // return () => {
    //   socket.off('gameStates', handleGameStates);
    // };

    // return () => {
    //   if (socket) {
    //     const channelId = localStorage.getItem('Channel ID');
    //     if (channelId) {
    //       socket.off(`race:${channelId}`); //TODO: CHANGE THE LOGIC OF THIS LINE OTHERWISE LEADERBOARD WILL NOT WORK
    //       // socket.off(`gameStates`);
    //     }
    //   }
    // };
    return () => {
      if (gameStateListenerRef.current) {
        const channelId = localStorage.getItem('Channel ID');
        if (channelId) {
          socket.off(`race:${channelId}`, gameStateListenerRef.current);
          console.log(`Listener removed from race:${channelId} for gameStates`);
        }
      }
    };
  }, [isSoundEnabled, socket]);

  const sendMessageToPhaser = useCallback((raceName, roundNo) => {
    setReplayBtnData({
      raceNo: raceName,
      roundNo: roundNo
    })

    if (raceName === 'Lucky Winner') {
      setMsg({ title: 'Lucky Winner', name: roundNo });
    }
    toggle();
  }, [setReplayBtnData, toggle])

  // * send own nft data in phaser
  const sendMsgOwnNft = useCallback((data) => {
    const iframe = document.getElementById('phaser-iframe');
    if (iframe) {
      iframe.contentWindow.postMessage({ message: 'Hello Phaser!', data: data }, '*');
    }
  }, [])

  //* LOGIN API
  useEffect(() => {
    if (location.search !== '') {
      const result = rfoxId.auth.handleAuthCallback(location.search);
      setCookie('token', result.token, 1)

      socket.emit("reqJoinBoard", {}, (error, data) => {
        if (Object.keys(data.oData).length !== 0 && data.oData.sTournamentState !== "No game found") {
          fetch(sRootUrl + `api/v1/game/auth/login?sTestnetMainnet=${data.oData.data?.sTestnetMainnet || 'mainnet'}`, {
            method: "POST",
            headers: {
              "Authorization": `Bearer ${result.token}`,
            }
            ,
          }).then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          }).then(data => {
            setCookie('token', result.token, 1)
            setLogginData(true);
            setUserData(data);
          }).catch(error => {
            removeCookie('token')
            window.location.href = '/'
          });
        }
      })
    }
  }, []);


  const handleBuyMoreRacers = useCallback(() => {
    // if (walletAddress !== undefined) {
    //   setIsBuyRaces(true);
    //   refetch()
    // } else {
    //   ReactToastify('You need to Login.', 'warning', 'warn')
    // }
    setIsBuyRaces(true);
    refetch()
  }, [refetch])

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      You can only access this after login
    </Tooltip>
  );

  const handleBannerClick = () => setOnBannerClick(true)

  // const queryString = new URLSearchParams(replayBtnData).toString();
  // const iframeUrl = `http://192.168.11.81:3530/editor/external/?sRoot=http://192.168.11.56:3005/&${queryString}`;
  return (<>
    <div className="main-background">
      <LazyLoadImage
        alt=''
        src={backgroundImg}
        width="100vw"
        height="100vh"
        style={{ objectFit: 'cover' }}
        effect="blur"
      />
    </div>
    <main className='main-sec'>
      <Container fluid>
        <Row>
          {width ? <>
            <div className='right-side-section'>
              <Row className='g-3'>
                <Col md={6} sm={12}>
                  <div className="last-meme-standing">
                    <img src={homeIcon} alt="" />
                    {/* <span>Last Meme Standing</span> */}
                  </div>
                </Col>
                <Col md={6} sm={12}>
                  <div className="price-pool-banner">
                    <LazyLoadImage
                      alt=''
                      src={bannerImg}
                      width='100%'
                      height='200px'
                      style={{ objectFit: 'cover' }}
                      effect="blur"
                      onClick={() => handleBannerClick()}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <Col sm={12} className='left-side'>
              <div className='left-side-section'>
                <GameCanvas isSoundEnabled={isSoundEnabled} togglePlay={toggleSound} userData={userData} gameStateData={gameStateData} />
              </div>
            </Col>

            <Col sm={12} className="middle-side-section">
              <div className="leaderboard">
                <LeaderboardList raceData={raceData} />
              </div>
              <div className='tournament-status'>
                <div className='tournament-status-title'>tournament status</div>
                <div className='d-flex justify-content-between'>
                  <div className='status'>
                    <div>remaining</div>
                    <div className='status-value'>{!isNaN(remainingNft) ? remainingNft : '0'}</div>
                  </div>
                  <div className='status'>
                    <div>eliminated</div>
                    <div className='status-value'>{gameStateData?.aTournamentLostNFT ?? "0"}</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} className='right-side' style={{ paddingTop: '0' }}>
              <div className='right-side-section'>
                <div className='user-profile'>
                  <RfoxIdProvider rfoxId={rfoxID}>
                    <div className='App'>
                      {logginData ? <LoggedInView userData={userData} /> : <NotLoggedInView sendMsgOwnNft={sendMsgOwnNft} />}
                    </div>
                  </RfoxIdProvider>

                </div>
                <div className='schedule-inventory'>
                  {walletAddress ? (
                    <>
                      <button
                        className={`box ${selectedTab.schedule ? 'active' : ''}`}
                        onClick={() => { setSelectedTab({ schedule: true }); getRaceScheduleData(); setRaceScheduleLoading(true); setInventoryData(inventoryData) }}
                      >
                        Race Schedule
                      </button>
                      <button
                        className={`box ${selectedTab.inventory ? 'active' : ''}`}
                        onClick={() => { setSelectedTab({ inventory: true }); getInventoryData(); setInventeryLoading(true) }}
                      >
                        Inventory
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className={`box ${selectedTab.schedule ? 'active' : ''}`}
                        onClick={() => setSelectedTab({ schedule: true })}
                      >
                        Race Schedule
                      </button>
                      <OverlayTrigger placement="top" overlay={renderTooltip}>
                        {({ ref, ...triggerHandler }) => (
                          <button
                            className="box"
                            ref={ref}
                            {...triggerHandler}
                          >
                            Inventory
                          </button>
                        )}
                      </OverlayTrigger>
                    </>
                  )}
                </div>

                <div>
                  {selectedTab.schedule && (
                    <div className='schedule-list' >
                      <div className="active-racer">
                        <ScheduleRaceList roundNFTs={roundNFTs} sendMessageToPhaser={sendMessageToPhaser} raceTimer={raceTimer} raceScheduleLoading={raceScheduleLoading} socket={socket} isStrargicTimoutZero={isStrargicTimoutZero} />
                      </div>
                      <div>
                        <button className='buy-more-racers' onClick={() => handleBuyMoreRacers()}>Buy More Racers {(isLoading || isFetching) && <Spinner animation='border' size='sm' />}</button>
                      </div>

                    </div>
                  )}
                  {selectedTab.inventory && (<InventoryList sendMessageToPhaser={sendMessageToPhaser} userData={userData} inventoryData={inventoryData} raceRoundTeam={raceRoundTeam} gameStateData={gameStateData} roundNFTs={roundNFTs} raceTimer={raceTimer} inventeryLoading={inventeryLoading} socket={socket} />)}
                </div>

                <div className='previous-tournament-section'>
                  <div className='inner-content'>
                    <h5>View Previous Tournaments Details</h5>
                    <Link
                      to="/previous-tournament-details"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visit Here
                    </Link>
                  </div>
                </div>

                <div>
                </div>
              </div>
            </Col>
          </> :
            <>
              <Col xl={5} lg={7} md={12} className='left-side'>
                <div className='left-side-section'>
                  <GameCanvas isSoundEnabled={isSoundEnabled} togglePlay={toggleSound} userData={userData} gameStateData={gameStateData} s />
                </div>
              </Col>

              <Col xl={3} lg={5} md={12} className='middle-side'>
                <MiddleSideSection onBannerClick={onBannerClick} setOnBannerClick={setOnBannerClick} bannerImg={bannerImg} handleBannerClick={handleBannerClick} remainingNft={remainingNft} gameStateData={gameStateData} raceData={raceData} raceRoundTeam={raceRoundTeam} />
              </Col>

              <Col xl={4} lg={12} md={12} className='right-side'>
                <div className='right-side-section'>
                  <div className='user-profile'>
                    <RfoxIdProvider rfoxId={rfoxID}>
                      <div className='App'>
                        {logginData ? <LoggedInView userData={userData} /> : <NotLoggedInView sendMsgOwnNft={sendMsgOwnNft} />}
                      </div>
                    </RfoxIdProvider>

                  </div>
                  <div className='schedule-inventory'>
                    {walletAddress ? (
                      <>
                        <button
                          className={`box ${selectedTab.schedule ? 'active' : ''}`}
                          onClick={() => { setSelectedTab({ schedule: true }); getRaceScheduleData(); setRaceScheduleLoading(true); setInventoryData(inventoryData) }}
                        >
                          Race Schedule
                        </button>
                        <button
                          className={`box ${selectedTab.inventory ? 'active' : ''}`}
                          onClick={() => { setSelectedTab({ inventory: true }); getInventoryData(); setInventeryLoading(true) }}
                        >
                          Inventory
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className={`box ${selectedTab.schedule ? 'active' : ''}`}
                          onClick={() => setSelectedTab({ schedule: true })}
                        >
                          Race Schedule
                        </button>
                        <OverlayTrigger placement="top" overlay={renderTooltip}>
                          {({ ref, ...triggerHandler }) => (
                            <button
                              className="box"
                              ref={ref}
                              {...triggerHandler}
                            // style={{ pointerEvents: 'none' }}
                            >
                              Inventory
                            </button>
                          )}
                        </OverlayTrigger>
                      </>
                    )}
                  </div>

                  <div>
                    {selectedTab.schedule && (
                      <div className='schedule-list' >
                        <div className="active-racer">
                          <ScheduleRaceList roundNFTs={roundNFTs} sendMessageToPhaser={sendMessageToPhaser} raceTimer={raceTimer} raceScheduleLoading={raceScheduleLoading} socket={socket} isStrargicTimoutZero={isStrargicTimoutZero} />
                        </div>
                        <div>
                          <button className='buy-more-racers' onClick={() => handleBuyMoreRacers()}>Buy More Racers {(isLoading || isFetching) && <Spinner animation='border' size='sm' />}</button>
                        </div>

                      </div>
                    )}
                    {selectedTab.inventory && (<InventoryList sendMessageToPhaser={sendMessageToPhaser} userData={userData} inventoryData={inventoryData} raceRoundTeam={raceRoundTeam} gameStateData={gameStateData} roundNFTs={roundNFTs} raceTimer={raceTimer} inventeryLoading={inventeryLoading} socket={socket} />)}
                  </div>

                  <div className='previous-tournament-section'>
                    <div className='inner-content'>
                      <h5>View Previous Tournaments Details</h5>
                      <Link
                        to="/previous-tournament-details"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Visit Here
                      </Link>
                    </div>
                  </div>

                  <div>
                  </div>
                </div>
              </Col>
            </>
          }
        </Row>
      </Container>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <button className='btnModalClose'><img src={iconClose} alt="" onClick={() => handleClose()} /></button>
          <div className='inner-content'>
            {msg !== '' ? <>
              <div className='luckywinner-sec'>
                <h1>{msg.title}s</h1>
                <ul className='lucky-winner-list'>
                  {msg?.name[0]?.map((winner, i) => {
                    return (
                      <Fragment key={i}>
                        <li>{winner?.sNFTName}</li>
                      </Fragment>
                    )
                  })}
                </ul>
              </div></> :
              <>
                <iframe id="phaser-iframe1" title="Meme Racer Game Replay" src={`${process.env.REACT_APP_REPLY_ENDPOINT}?sRoot=${sRootUrl}&raceNo=${replayBtnData.raceNo}&roundNo=${replayBtnData.roundNo}&address=${walletAddress ? walletAddress : null}&page=1&limit=100&collectionName=${gameStateData.sCollectionName}&schemaName=${gameStateData.sSchema}&testnet=${gameStateData?.sTestnetMainnet === 'testnet' ? true : false}`}></iframe>
                {/* <iframe id="phaser-iframe1" src={iframeUrl} ></iframe> */}
              </>
            }
          </div>
        </ModalBody>
      </Modal>
    </main >
  </>
  )
}

export default Home
