import axios from '../../axios'

export const viewBannerData = () => {
    return axios.get(process.env.REACT_APP_API_ENDPOINT + `api/v1/admin/banner/view?t=${new Date().getTime()}`)
}

export const viewBackgroundImage = () => {
    return axios.get(process.env.REACT_APP_API_ENDPOINT + `api/v1/admin/bg/view?t=${new Date().getTime()}`)
}

export const getSaleUrl = () => {
    return axios.get(process.env.REACT_APP_API_ENDPOINT + `api/v1/game/profile/saleUrl?t=${new Date().getTime()}`)
}